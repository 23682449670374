import { isDeviceId, isPersistedProbability } from './validation.js';
class InMemoryPersistence {
  constructor() {
    this.persistedItems = new Map();
  }
  async load(key) {
    return this.persistedItems.get(key);
  }
  async save(key, value) {
    this.persistedItems.set(key, value);
  }
}
function toPersistedPayload(key, raw) {
  switch (key) {
    case 'bugsnag-sampling-probability':
      {
        const json = JSON.parse(raw);
        return isPersistedProbability(json) ? json : undefined;
      }
    case 'bugsnag-anonymous-id':
      return isDeviceId(raw) ? raw : undefined;
  }
}
export { InMemoryPersistence, toPersistedPayload };