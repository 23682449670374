class RequestTracker {
  constructor() {
    this.callbacks = [];
  }
  onStart(startCallback) {
    this.callbacks.push(startCallback);
  }
  start(context) {
    const endCallbacks = [];
    for (const startCallback of this.callbacks) {
      const endCallback = startCallback(context);
      if (endCallback) endCallbacks.push(endCallback);
    }
    return endContext => {
      for (const endCallback of endCallbacks) {
        endCallback(endContext);
      }
    };
  }
}
export { RequestTracker };