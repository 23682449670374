import { BaseRequestService } from './base-request.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    CouponCodeBean,
    IngredientStateBean,
    ManageKioskCustomRecipesBean,
    ManageKioskDescriptionBean,
    ManageKioskDiagnosticStatesBean,
    ManageKioskMaintenanceOptionsBean,
    ManageKioskRewardsBean,
    ManageKioskWorkingHoursBean,
    RecipeListStateBean,
    RecipeStateBean,
    ResponseBean,
    ResponseItemBean,
    UserBean
} from '../model/model';

@Injectable()
export class KioskManageService extends BaseRequestService {

    private static baseUrl(id: number, path: string) {
        return 'admin/kiosk/' + id + '/manage/' + path;
    }

    getDescription(id: number): Observable<ResponseItemBean<ManageKioskDescriptionBean>> {
        return this.get(KioskManageService.baseUrl(id, 'description'));
    }

    setDescription(id: number, bean: ManageKioskDescriptionBean): Observable<ResponseBean> {
        return this.put(KioskManageService.baseUrl(id, 'description'), bean);
    }

    getNotificationEmails(id: number): Observable<ResponseItemBean<string[]>> {
        return this.get(KioskManageService.baseUrl(id, 'notification_emails'));
    }

    setNotificationEmails(id: number, bean: string[]): Observable<ResponseBean> {
        return this.put(KioskManageService.baseUrl(id, 'notification_emails'), bean);
    }

    getCustomRecipes(id: number): Observable<ResponseItemBean<ManageKioskCustomRecipesBean>> {
        return this.get(KioskManageService.baseUrl(id, 'custom_recipes'));
    }

    setCustomRecipes(id: number, bean: ManageKioskCustomRecipesBean): Observable<ResponseBean> {
        return this.put(KioskManageService.baseUrl(id, 'custom_recipes'), bean);
    }

    getIngredients(id: number): Observable<ResponseItemBean<IngredientStateBean[]>> {
        return this.get(KioskManageService.baseUrl(id, 'ingredients'));
    }

    setIngredients(id: number, bean: IngredientStateBean[]): Observable<ResponseBean> {
        return this.put(KioskManageService.baseUrl(id, 'ingredients'), bean);
    }

    getManagers(id: number): Observable<ResponseItemBean<UserBean[]>> {
        return this.get(KioskManageService.baseUrl(id, 'managers'));
    }

    setManagers(id: number, bean: UserBean[]): Observable<ResponseBean> {
        return this.put(KioskManageService.baseUrl(id, 'managers'), bean);
    }

    getTesterEmails(id: number): Observable<ResponseItemBean<string[]>> {
        return this.get(KioskManageService.baseUrl(id, 'tester_emails'));
    }

    setTesterEmails(id: number, bean: string[]): Observable<ResponseBean> {
        return this.put(KioskManageService.baseUrl(id, 'tester_emails'), bean);
    }

    getBlacklistEmails(id: number): Observable<ResponseItemBean<string[]>> {
        return this.get(KioskManageService.baseUrl(id, 'blacklist_emails'));
    }

    setBlacklistEmails(id: number, bean: string[]): Observable<ResponseBean> {
        return this.put(KioskManageService.baseUrl(id, 'blacklist_emails'), bean);
    }

    getWorkingHours(id: number): Observable<ResponseItemBean<ManageKioskWorkingHoursBean>> {
        return this.get(KioskManageService.baseUrl(id, 'working_hours'));
    }

    setWorkingHours(id: number, bean: ManageKioskWorkingHoursBean): Observable<ResponseBean> {
        return this.put(KioskManageService.baseUrl(id, 'working_hours'), bean);
    }

    getCoupons(id: number): Observable<ResponseItemBean<CouponCodeBean[]>> {
        return this.get(KioskManageService.baseUrl(id, 'coupons'));
    }

    setCoupons(id: number, bean: CouponCodeBean[]): Observable<ResponseBean> {
        return this.put(KioskManageService.baseUrl(id, 'coupons'), bean);
    }

    getMaintenanceOptions(id: number): Observable<ResponseItemBean<ManageKioskMaintenanceOptionsBean>> {
        return this.getWithKioskToken(id, KioskManageService.baseUrl(id, 'maintenance_options'), 1);
    }

    setMaintenanceOptions(id: number, bean: ManageKioskMaintenanceOptionsBean): Observable<ResponseBean> {
        return this.put(KioskManageService.baseUrl(id, 'maintenance_options'), bean);
    }

    getRewards(id: number): Observable<ResponseItemBean<ManageKioskRewardsBean>> {
        return this.get(KioskManageService.baseUrl(id, 'rewards'));
    }

    setRewards(id: number, bean: ManageKioskRewardsBean): Observable<ResponseBean> {
        return this.put(KioskManageService.baseUrl(id, 'rewards'), bean);
    }

    getRecipeLists(id: number): Observable<ResponseItemBean<RecipeListStateBean[]>> {
        return this.get(KioskManageService.baseUrl(id, 'recipe_lists'));
    }

    setRecipeLists(id: number, bean: RecipeListStateBean[]): Observable<ResponseBean> {
        return this.put(KioskManageService.baseUrl(id, 'recipe_lists'), bean);
    }

    getRecipes(id: number): Observable<ResponseItemBean<RecipeStateBean[]>> {
        return this.get(KioskManageService.baseUrl(id, 'recipes'));
    }

    setRecipes(id: number, bean: RecipeStateBean[]): Observable<ResponseBean> {
        return this.put(KioskManageService.baseUrl(id, 'recipes'), bean);
    }

    getDiagnosticStates(id: number): Observable<ResponseItemBean<ManageKioskDiagnosticStatesBean>> {
        return this.get(KioskManageService.baseUrl(id, 'diagnostic_states'));
    }

    setDiagnosticStates(id: number, bean: ManageKioskDiagnosticStatesBean): Observable<ResponseBean> {
        return this.put(KioskManageService.baseUrl(id, 'diagnostic_states'), bean);
    }

    getUserMessages(id: number): Observable<ResponseItemBean<string[]>> {
        return this.get(KioskManageService.baseUrl(id, 'user_messages'));
    }

    setUserMessages(id: number, bean: string[]): Observable<ResponseBean> {
        return this.put(KioskManageService.baseUrl(id, 'user_messages'), bean);
    }
}
